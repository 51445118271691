import React, { useEffect, useState } from "react"
import { Button } from "@material-ui/core"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import IconButton from "@material-ui/core/IconButton"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { useDispatch, useSelector } from "react-redux"
import { agregarCarritoActions } from "../../state/reducers/carritoReducers"

const Buttons = ({ ProductData, title}) => {
  const dispatch = useDispatch()
  const { carrito } = useSelector(state => state.carrito)
  const [cantidad, guardarCantidad] = useState(1)
  const [baseProducto, guardarBaseProducto] = useState("Y")
  const estaEnCarrito = carrito.find(
    i => i.IdRelacion === ProductData.IdRelacion
  )
  return (
    <>
      <div className="mt-0 mb-0 relative">
        <div>
          <IconButton
            onClick={e =>
              guardarCantidad(
                cantidad > 1 ? cantidad - 1 : cantidad
              )
            }
            variant="contained"
            color="primary"
            size="medium"
          >
            <RemoveCircleIcon />
          </IconButton>
          <span>{cantidad}</span>
          <IconButton
            size="medium"
            variant="contained"
            color="primary"
            onClick={e => guardarCantidad(cantidad + 1)}
          >
            <AddCircleIcon />
          </IconButton>
        </div>


        {estaEnCarrito ? (
          <Button className="bg-primary_azul rounded text-white px-7">
           En Cotizacion
          </Button>
        ) : (
          <Button
            onClick={e =>
              dispatch(
                agregarCarritoActions(
                  ProductData,
                  cantidad,
                  carrito,
                  baseProducto,
                  ProductData.BaseDefault !== undefined ? false : true
                )
              )
            }
            className="bg-primary rounded text-white px-7 "
          >
            {title}
          </Button>
        )}
      </div>
    </>
  )
}

export default Buttons
